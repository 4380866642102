import React, {  useReducer} from 'react';
import ReactDOM from 'react-dom';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {App,Err,Login,ListForm} from './pages';
// import SearchRender from "./SearchRender";
import 'antd/dist/antd.css';
import  './utils/td.css';
import {reducer,APPContext,InitStory} from "./reducer";


ReactDOM.render(
          <Router >
              <Switch>
                  <Route exact path="/login"  component={Login}/>
                  <Route exact path="/error"  component={Err}/>				  
                  <Route exact path="/wayb/listForForm"  component={ListForm}/>
                  <Route path="/"   component={Start} />
                  <Redirect to="/error"  />
              </Switch>
          </Router>

    , document.getElementById('root')
);

// 由于useReducer只能在标准组件中使用，不能在ReactDOM.render中直接使用，所以用Home组件做过渡
// 效果上是等于加载了app组件。

function Start() {

    const [story, dispatch] = useReducer(reducer, InitStory)

    return(
        <APPContext.Provider value={{story, dispatch}}>
           <App/>
        </APPContext.Provider>
    )

}










