
import {Row} from 'antd'

function Err() {

	return (

		<Row type="flex" justify="center" align="middle"  style={{ minWidth:'10vh',minHeight:'69vh'  }} >
			<h1 style={{fontSize:'30px'}}>404! in Err </h1>

		</Row>

	);
}



export  {Err};
