import React from 'react';
import {Drawer,Descriptions} from 'antd';

const ApiHelp =(props)=>{	
	
	const {onCancel,visible,item} =props
	
	let width = window.innerWidth <768 ? window.innerWidth*0.8 :window.innerWidth*0.35
	return (
	
	<Drawer   title="接口通用说明"    placement="right"      closable={false}      onClose={onCancel}    visible={visible}  width={width}  >
	<p>本API调试工具是为解决后端、ESB需验证token的接口调试不便问题<br/>服务器地址取config目录下server的配置</p>
	<p>示例接口: /api/test,无参数</p>
	<Descriptions layout="vertical" bordered>
		
		<Descriptions.Item label="格式">http RestFul</Descriptions.Item><Descriptions.Item label="方法">POST</Descriptions.Item>
		<Descriptions.Item label="请求头">JSON/UTF8</Descriptions.Item>	

		<Descriptions.Item label="后端标准对象接口说明" span={3}>
			<span>1、参数及字段名参见数据库字段名，ORM印射</span><br/>
			<span>{`2、默认支持以下7种方法，以对象user为例：
					/user  查找单条数据
					/user/create  增加单条数据
					/user/findAll 查找全部数据集，默认限制5000条
					/user/update 更新单条数据
					/user/destroy 软删除指定记录
					/user/restore 恢复软删除指定记录
					/user/count 统计总记录数`}</span><br/>
			<span>{`3、按字段内容查找。提供字段名和条件，示例：{name:'张三',age:20}`}</span><br/>
			<span>{`4、删除和恢复删除只需提供id即可，示例：{id::20}`}</span><br/>
			<span>{`5、{id:1,name:'张三',age:20}表示更新ID为1的记录，更新内容是name和age字段。}`}</span><br/>					
			<span>{`6、增加、查找、修改都会返回所操作id记录的全部字段供查验数据`}</span><br/>
			<span>{`7、findAll方法表示全部记录，支持limit,order,offset.paranoid,include,attributes参数。其中，limit\order\offset为数字。`}</span><br/>
			<span>{`8、findAll方法默认返回全部字段，可以通过attributes指定需返回的字段。示例: {attributes:['id','name']}`}</span><br/>
			<span>{`9、findAll方法支持排序，{order:['id','name','[['age','desc']]}`}</span><br/>
		</Descriptions.Item>	
		<Descriptions.Item label="后端返回值说明" span={3}>
					{`1、成功，{status:true,data:[]}`}<br/>status为true表示后端处理成功，data是所请求的数据集，为JsonArray<br/>{`2、失败 {status:false,data:'错误消息提示'}`}<br/>
				status为false表示失败，data中显示提醒消息<br/>3、请求时设置20S超时
				</Descriptions.Item>					
	  </Descriptions><br/>
	  	
	   
      </Drawer>
	
	)

}


export default ApiHelp;
