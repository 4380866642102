
import List from './wayb/list/index';
import { ConfigProvider  } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

function ListForm() {

    return (
        <div  style={{ background:'white',minHeight:'100vh',paddingLeft :'15px',paddingRight:'15px'  }} >
            <ConfigProvider locale={zhCN}>
                <List/>
            </ConfigProvider>
        </div>

    );
}



export  {ListForm};
