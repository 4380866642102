
//标准菜单
const menuStandard =[
	{
		url:'wayb',	title:'TMS明细', Icon:'CarOutlined',
		Item:[{url:'/wayb/list',title:'运单',Icon:'UnorderedListOutlined',component:'./wayb/list/index'},{url:'/wayb/listAll',title:'运单-all',Icon:'OrderedListOutlined',component:'./test/TestColor'}]
	},{
		url:'waybillAudit',	title:'TMS明细(审计)', Icon:'CarOutlined',
		Item:[{url:'/waybillAudit/list',title:'运单',Icon:'UnorderedListOutlined',component:'./waybillAudit/list/index'}]
	},{
		url:'waybReport',	title:'TMS报表', Icon:'CarOutlined',
		Item:[{url:'/waybReport/clientByMon',title:'客户月报',Icon:'UnorderedListOutlined',component:'./waybreport/clientrpt/index'},
			{url:'/waybReport/carrierByMon',title:'承运月报',Icon:'OrderedListOutlined',component:'./waybreport/carrierRpt'},
			{url:'/waybReport/carByMon',title:'车辆月报',Icon:'OrderedListOutlined',component:'./waybreport/vehicleRpt'},
		]
	},
	{
		url: 'uploadwaybill', title: '上传运单', Icon: 'DiffOutlined',
		Item: [{
			url: '/uploadwaybill/list', title: '上传运单',
			Icon: 'UnorderedListOutlined',
			component: './uploadwaybill/list/Upwaybill'
		},
		]
	},
	{
		url: 'pushWaybill', title: '推送记录', Icon: 'BuildOutlined',
		Item: [{
			url: '/pushWaybill/list', title: '预检记录',
			Icon: 'UnorderedListOutlined',
			component: './pushWaybill/list/pushWaybill'
		},
		]
	},
	{
		url: 'elecontract', title: '电子合同检查', Icon: 'EyeOutlined',
		Item: [{
			url: '/elecontract/list', title: '电子合同检查',
			Icon: 'UnorderedListOutlined',
			component: './elecontract/list/eleContract'
		},
		]
	},
	{
		url:'setting',	title:'设置/配置', Icon:'SettingOutlined',
		Item:[{url:'/user/list',title:'用户',Icon:'UsergroupAddOutlined',component:'./setting/user'},{url:'/setting/roleAuth',title:'角色配置',Icon:'GroupOutlined',component:'./setting/RoleAuth'},
			{url:'/setting/parameter',title:'参数',Icon:'ShareAltOutlined',component:'./setting/parameters'},{url:'/setting/password',title:'修改密码',Icon:"SafetyOutlined",component:'./setting/ChangePwd'}]
	},{
		url:'test',	title:'测试', Icon:'ToolOutlined',  // 这里的Icon 已经升级为antd4的Icon,需要用<CommentOutlined />的方式加载
		Item:[{url:'/test/api',title:'API接口',Icon:'ForkOutlined',component:'./test/TestApi'},  //component 是App.js为运行目录的做的相对路径引用
			{url:'/test/color',title:'颜色',Icon:'BgColorsOutlined',component:'./test/TestColor'}]
	},

];

//标准按钮(admin)
//btnStandard  某个菜单必须选一个按钮，否则视为没有权限打开。至少要开放刷新按钮。
//放这里的目的是给权限配置看的。需要和页面中按钮ID对应。所有KEY需要唯一。如果不唯一会造成权限配置时无法配置
const btnStandard =[
	{key:'/user/list',btn:[{key:'/user/list/refresh',title:'刷新'},{key:'/user/list/btn001',title:'添加'},{key:'/user/list/edit',title:'修改'}]},
	{key:'/client/pending',btn:[{key:'/client/pending/refresh',title:'刷新'},{key:'/client/pending/btn001',title:'审核'},{key:'/client/pending/btn002',title:'上传合同'}]},
	]


export  {menuStandard,btnStandard}

