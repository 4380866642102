import { Modal} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';


function ExportWaitModal(setVisible) {
	return (
		<Modal width ={'200px'} style={{marginTop:'200px'}} visible ={setVisible} keyboard={false} closable ={false} footer={null} >
			{'暂时最多支持1000条数据，正在努力导出中，公子莫急^0^'}&nbsp;&nbsp;&nbsp;&nbsp;<LoadingOutlined />
		</Modal>
	);
}

export {ExportWaitModal};
