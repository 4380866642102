import { Modal} from 'antd';
import {APPContext} from "../reducer";
import {useContext}from 'react';


function OfflineModal() {

	const appCtx = useContext(APPContext)
	const {offline} =appCtx.story;

	return (

		<Modal visible ={offline} keyboard={false} closable ={false} footer={null}>
			您的网络掉线了

		</Modal>

	);
}

export {OfflineModal};
