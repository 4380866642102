import React from "react";

export * from './isnull';
export * from './axios';
export * from './array';
export * from './obj';
export * from './asyncComponent';
export * from './url';



function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
    return uuid;
}

const getIcon =(iconName)=>{

    let  Icon = require('@ant-design/icons')[iconName];
    return (<Icon  />);
}


export {generateUUID,getIcon};





