
const RemoveEmptyEle =(para)=>{		
	
	for (let i in para){
		if (para[i]==='undefined' || para[i] ===undefined || para[i] ==='' ){
			delete para[i];
		}		
	}	
	
	return para;

}

var removePropertyOfNull=function(obj){
	Object.keys(obj).forEach(item=>{
		if(!obj[item])  delete obj[item]
	})
	return obj;
}



export {RemoveEmptyEle,removePropertyOfNull};