function download(path,filename) {
       var a = document.createElement('a');
      var url = path+filename
      a.href = url;
      a.download =filename;
      a.click();
      window.URL.revokeObjectURL(url);

}

function download2(path,filename) {
            const elt = document.createElement('a');
			elt.setAttribute('href', path + filename);
			elt.setAttribute('download',filename);
			elt.style.display = 'none';
			document.body.appendChild(elt);
			elt.click();
			document.body.removeChild(elt);
}


function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
    }
    return(false);
}

export {download,download2,getQueryVariable}
