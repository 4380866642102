
const env ='prd';

const envList={

     // prd:'he.da-jing.com/serverapi',
	 prd:'10.0.0.22:23002',

     prd_local:'10.0.3.71:23002',

}

const server =envList[env];


export  {server,envList}
