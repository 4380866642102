
import {Row} from 'antd'

function Home() {

	return (

		<div>
			<Row type="flex" justify="center" align="middle"  style={{ minWidth:'10vh',minHeight:'20vh'  }} >
				<h1 style={{fontSize:'30px'}}>欢迎访问货运导航监管软件  </h1>

			</Row>
			<Row  >
			<img src={'/image/bg1.png'} alt={'bg1.png'} width={'100%'}/>
			</Row>

		</div>

	);
}



export  default Home;
