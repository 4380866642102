import { Modal} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import ApiHelp from "./ApiHelp";


function LoadingModal({setVisible}) {

	return (

		<Modal width ={'200px'} style={{marginTop:'200px'}} visible ={setVisible} keyboard={false} closable ={false} footer={null} >
			 {' 正在努力加载中 '}&nbsp;&nbsp;&nbsp;&nbsp;<LoadingOutlined />
		</Modal>

	);
}

export default LoadingModal;
