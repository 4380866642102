import axios from 'axios';
import {server} from '../config';
import {message} from "antd";

const AxiosServer =function(){

	//从浏览器内存中获取token。
	if (sessionStorage.getItem('token')){
		axios.defaults.headers['token'] =sessionStorage.getItem('token');
	}
	let isHttps = 'https:' === document.location.protocol ? true : false;
	if (isHttps) {
		axios.defaults.baseURL ='https://' + server;
	}else{
		axios.defaults.baseURL ='http://' + server;
	}


	axios.defaults.timeout = 1000*20;
	
	return axios;
};


const aPost=(url,values)=>{
	//通用get方法，都是post方法+json格式。
	try{
		let Axios = AxiosServer();
		return Axios.post(url,values).then(d=>d.data).catch(err=>err);
	}
	catch (e) {
		console.log('from url',url,'error:',e.message)
		return e.message;
	}
};

export {AxiosServer,aPost}
