import React,{useState}  from 'react';
import {Input, Form, DatePicker, Button, Row, Col, Select,Card} from 'antd';
import {getIcon, RemoveEmptyEle, removePropertyOfNull} from '../../../utils';
import moment from 'moment';


const { RangePicker } = DatePicker;


const SearchRender =({searchProps,onFinish,onReset,onExcelAll})=>{

	const formLargeItemLayout = {
		labelCol: {
			span: 4,
		},
		wrapperCol: {
			span: 12,
		},
	}
	const { Option } = Select;
	const  handleSubmit =(values)=>{
		let newValue ={...values};
		 newValue = removePropertyOfNull(newValue);
		let new_open_order_date =[];
		if(null != newValue.open_order_date){
			new_open_order_date.push(moment( newValue.open_order_date[0]).format('YYYY-MM-DD'));
			new_open_order_date.push(moment( newValue.open_order_date[1]).format('YYYY-MM-DD'));
			newValue.open_order_date =new_open_order_date;
		}
		onFinish(newValue);
	}
	const reset=(e)=>{
		onReset();
	}
	//导出全部功能
	const excelAll=(e)=>{
		onExcelAll();
	}
	return (
		<Form  {...formLargeItemLayout} onFinish={handleSubmit}  style={{marginBottom:'10px'}}  >
				<Row >
					<Col span={6} >
						<Form.Item label='运单号' name="carrier_waybill_no" style={{marginBottom:'10px'}}	>
							<Input placeholder="YC2105150104" />
						</Form.Item>

					</Col>
					<Col span={6} >

						<Form.Item label='承运人' name="to_carrier_name" style={{marginBottom:'0px'}}	>
							<Input placeholder="郝南贵" />
						</Form.Item>

					</Col>

					<Col span={6} >

						<Form.Item label='车牌号' name="vehicle_no"   style={{marginBottom:'0px'}}	>
							<Input placeholder="鲁Q212GM" />
						</Form.Item>

					</Col>

					<Col span={6} >

						<Form.Item label='起运时间' name="open_order_date"  style={{marginBottom:'0px'}}	>
							<RangePicker/>
						</Form.Item>

					</Col>

					<Col span={6} >

						<Form.Item label='获票方式' name="billing_type"  style={{marginBottom:'10px'}}	>
							<Select placeholder="全部"  >
								<Option>全部</Option>
								<Option value="01">自开</Option>
								<Option value="02">代征</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} >

						<Form.Item label='运输类型' name="trans_type"  style={{marginBottom:'0px'}}	>
							<Select placeholder="全部"  >
								<Option>全部</Option>
								<Option value="1">汽运</Option>
								<Option value="2">船运</Option>
							</Select>
						</Form.Item>

					</Col>
					{/*<Col span={6} >*/}

					{/*	<Form.Item label='主体一致' name="subjectflag"  style={{marginBottom:'0px'}}	>*/}
					{/*		<Select placeholder="全部"  >*/}
					{/*			<Option>全部</Option>*/}
					{/*			<Option value="1">一致</Option>*/}
					{/*			<Option value="2">不一致</Option>*/}
					{/*		</Select>*/}
					{/*	</Form.Item>*/}

					{/*</Col>*/}
					{/*<Col span={6} >*/}

					{/*	<Form.Item label='金额一致' name="moenyflag"  style={{marginBottom:'0px'}}	>*/}
					{/*		<Select placeholder="全部"  >*/}
					{/*			<Option>全部</Option>*/}
					{/*			<Option value="1">一致</Option>*/}
					{/*			<Option value="2">不一致</Option>*/}
					{/*		</Select>*/}
					{/*	</Form.Item>*/}

					{/*</Col>*/}
					{/*<Col span={6} >*/}

					{/*	<Form.Item label='超载' name="coverloadflag"  style={{marginBottom:'0px'}}	>*/}
					{/*		<Select placeholder="全部"  >*/}
					{/*			<Option>全部</Option>*/}
					{/*			<Option value="2">超载</Option>*/}
					{/*			<Option value="1">不超载</Option>*/}
					{/*		</Select>*/}
					{/*	</Form.Item>*/}

					{/*</Col>*/}
					<Col span={18} align={'right'}  >
					<span display={'inline'}>
						<Button type="primary" htmlType="submit" >  查询</Button>
						<Button htmlType="reset" onClick={reset}>  重置</Button>
						<Button  onClick={excelAll} icon={getIcon('ArrowDownOutlined')}> 导出全部</Button>
					</span>
					</Col>
				</Row>
      </Form>

	)

}




export default SearchRender;
