import React,{useState} from 'react';
import {Table,Divider,Row,Col,Tooltip,Tag,Button,message,Popover} from 'antd';
import {aPost, AxiosServer, download2, getIcon} from '../../../utils';
import moment from 'moment';
import SearchRender from "./SearchRender";
import WaybListHelp from "../../../components/waybListHelp";
import {ExportWaitModal} from "../../../components/ExportWaitModal";

function List() {

	/*
	* 用户列表，支持在线查看，重置密码，添加和修改用户信息
	* */

	//定义state变量,set方法。

	let dataFetchPropsInit ={deleted:0,order:[['randnum','desc']],

	}

	let initState ={
		data:[],
		dataLoading: true,
		exportLoading:false,
		dataFetchProps: {...dataFetchPropsInit},
		waybListHelpVisible: false,
		selectedRow: {},
		selectedRowIndex: -1,
		selectedRows: [],
		selectedRowKeys: [],
		pagination: {
			showSizeChanger: false, // Hide the page size changer
			showQuickJumper: false,  // Hide the quick jumper
			pageSizeOptions: ['5', '10', '20'],
			defaultPageSize: 5,
			pageSize: 5,
			current: 1,
			defaultCurrent: 1,
			simple:true,

			itemRender: (current, type, originalElement) => {
				if (type === 'prev') {
					return '<'; // Customize the 'Previous' label
				}
				if (type === 'next') {
					return '>'; // Customize the 'Next' label
				}
				return null;
			},
		},
		searchProps:{},

	};

	const [state,setState] =useState(initState);  //state 是当前页的变量集合
	const set =(values)=>setState({...state,...values});

	//导入本组件所需参数变量
	let {data, dataLoading, dataFetchProps, searchProps,selectedRows,selectedRow, selectedRowKeys, selectedRowIndex,pagination,
		waybListHelpVisible,exportLoading} = state;

	// 定义get方法，从服务器获取数据
	const get =async ()=>{
		let {current:currentPage,pageSize} =pagination;
		let Props ={...dataFetchProps,...searchProps,currentPage,pageSize};
		let resp = await aPost('/waybillTotal/findAll',Props)
		if (!resp.status){
			set({dataLoading:false})
		}else{
			// let paginationNew ={...pagination,total:resp.count}
			if(resp.count <5000){
				resp.count = 10000
			}
			let paginationNew ={...pagination,total:resp.count}
			set({data:resp.data,pagination:paginationNew,dataLoading:false})
		}
	}


	//dataFetchProps
	if(dataLoading){ get();	}
	const openHelp =()=>{
		set({waybListHelpVisible:true});
	}
//定义表头
	const columns = [
		{
			title: '承运端',
			children:[{
				title:'承运单号',
				dataIndex: 'carrier_waybill_no',
				width:160,fixed: 'left',
				render:(text,record) =>{
					let ydurl ='http://luxiaoer.cn/carrier/getCarrierWayBillInfo?carrierWaybillNo=' + text;
					return (<span>
				<Row>{text}</Row>
				<Row><a target='_blank' href={ydurl}>运单</a><Divider type="vertical"/>
				<a target='_blank' href={record.yc_contract_url}>{record.yc_contract_url?'合同':null}</a></Row>
			</span>)
				}
			},{
				title:'承运人',
				dataIndex: 'to_carrier_name',
				width:80,fixed: 'left',

			},]
		},
		{
			title: '',
			children:[{
				title:'类型',
				dataIndex: 'trans_type',
				key:'运输类型',
				width:50,
				render:(text) =>{
					if (null==text || text.length<1){
						return null
					}else{
						let str =text ==1 ?'车':'船';
						return (<div >{str}</div>)
					}
				}
			},{
				title:'起运地',
				dataIndex: 'loading_place_province_name',
				key:'装起运地',
				width:100,
				render:(text,record) =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text  +record.loading_place_city_name}</span>)
					}
				}
			},{
				title:'目的地',
				dataIndex: 'unloading_place_province_name',
				render:(text,record) =>record.unloading_place_province_name + record.unloading_place_city_name,
				width:100
			},{
				title:'起运日期',
				dataIndex: 'open_order_date',
				width:110,
				sorter: (a, b) =>  a.open_order_date.localeCompare(b.open_order_date) ,
				render: (text) => {
					return (<span>{moment(text).format('YYYY-MM-DD')}</span>)
				}
			},{
				title:'到达日期',
				dataIndex: 'actual_arrival_time',
				render:text =>text ? moment(text).format('YYYY-MM-DD'):null,
				width:110
			},{
				title:'里程数',
				dataIndex: 'mile_age',
				width:100
			},{
				title:'车船号',
				dataIndex: 'vehicle_no',
				width:100,
				render:(text,record) =>{
					if (null==text || text.length<1){
						return	(<span>{null}</span>)
					}
					let carTraceurl ='http://luxiaoer.cn/carrier/getCarrierTrackInfo?carrierWaybillNo='+ record.carrier_waybill_no + '&vehicleNo='+ text;
					let shipTraceUrl ='http://luxiaoer.cn/carrier/getShippingTrackInfo?carrierWaybillId='+ record.carrier_waybill_id +'&carrierWaybillNo='+ record.carrier_waybill_no +'&vehicleNo='+ text +
						'&beginDate='+ moment(record.open_order_date).format('YYYY-MM-DD')+'&endDate='+ record.actual_arrival_time;
					let traceUrl = record.trans_type ==1 ? carTraceurl :shipTraceUrl;
					return (<span>
						<Row>{text}</Row>
						<Row><span><a target='_blank' href={traceUrl }>{text?'轨迹':null}</a><Divider type="vertical"/></span></Row>
						</span>)
				}
			},
			// {
			// 	title:'核载',
			// 	dataIndex: 'm_getVehicle',
			// 	width:100,
			// 	render:(text) =>{
			// 		if (!text[0] || text.length<1){
			// 			return	(<span>{null}</span>)
			// 		}
			// 		let d=text[0];
			// 		return (<span>{d.vehicle_load}</span>)
			// 	}
			// },
				{
				title:'获票方式',
				dataIndex: 'billing_type',
				width:100,
				render:text =>{
					let str =text ==='01' ?'自开':'代征';
					return (<span>{str}</span>);
				}
			},{
				title:'收到发票号码',
				dataIndex: 'invoice_no',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'收到发票主体',
				dataIndex: 'invoice_unit_name',
				key:'收到发票主体',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'收票金额',
				dataIndex: 'invoice_money',
				key:'收票金额'	,
				width:100,
				render:(text,record) =>{
					if (null==text || text.length<1){
						return null
					}else{
						let isOk = record.moenyflag==2 ? <span style={{color:"red"}}>{text}</span>: <span>{text}</span>
						return isOk
					}
				}
			},{
				title:'收到发票开票日期',
				dataIndex: 'invoice_date',
				key:'收到发票开票日期',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{moment(text).format('YYYY-MM-DD')}</span>)
					}
				}
			},{
				title:'承运总金额',
				dataIndex: 'carrier_total_fee_total',
				key:'承运总金额',
				width:100,
					render:(text,record) =>{
					if (null==text || text.length<1){
						return null
					}else{
						let isOk = record.moenyflag==2 ? <span style={{color:"red"}}>{text}</span>: <span>{text}</span>
						return isOk
					}
				}
			},{
				title:'开单重量',
				dataIndex: 'weight_total',
				key:'开单重量',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'承运合同重量',
				dataIndex: 'real_weight_total',
				key:'承运合同重量',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'收款人',
				dataIndex: 'collection_name',
				key:'收款人',
				width:130,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			}, {
				title: '付款时间',
				dataIndex: 'paymenttime',
				width: 130,
				render:(text,record) =>{
					if (null==text || text.length<1) {
						return null
					} else {
						return (<Tooltip placement="topLeft" title={'付款流水号' + record.check_no}>
							<Tag color="#2e6eb8">{moment(text).format('YYYY-MM-DD')}</Tag> </Tooltip>);
					}
				}
			},{
				title:'司机',
				dataIndex: 'driver_name',
				key:'司机',
				width:130,
				render:(text,record) =>{
					if (null==text || text.length<1) {
						return null
					}else{
						return (<Tooltip placement="topLeft" title={'身份证' + record.id_card}><Tag color="#2E8B57">{record.driver_name}</Tag> </Tooltip>)
					}
				}
			},{
					title:'司机证件照',
					dataIndex: '',
					key:'司机',
					width:130,
					render:(text,record) =>{
						let element =[];
						if (record.driver_front_path) {
							element.push(<p><a target='_blank' href={record.driver_front_path}>驾驶证</a></p>);
						}
						if(record.transport_img){
							element.push(<p><a target='_blank' href={record.transport_img}>从业资格证</a></p>);
						}
						if(record.id_photo_correct_img){
							element.push(<p><a target='_blank' href={record.id_photo_correct_img}>身份证</a></p>);
						}
						return element;
					}
				},
				// {
				// 	title:'保险单号',
				// 	dataIndex: 'm_ykWaybill',
				// 	key:'保险单号',
				// 	width:130,
				// 	render:text =>{
				// 		if (!text.length && text.length<1){
				// 			return null
				// 		}else{
				// 			if (text[0].downlowdurl) {
				// 				return (<a target='_blank' href={text[0].downlowdurl}>{text[0].policyno}</a>)
				// 			} else {
				// 				return (<span>{text[0].policyno}</span>)
				// 			}
				// 		}
				// 	}
				// },
				{
					title:'货物名称',
					dataIndex: 'material_desc',
					key:'货物名称',
					width:100,
					render:text =>{
						if (null==text || text.length<1) {
							return null
						}else{
							return (<span>{text}</span>)
						}
					}
				},]
		},

		{
			title: '货主端',
			children:[{
				title:'客户单号',
				dataIndex: 'waybill_no',
				key:'客户单号',
				width:160,
				render:(text,record) =>{
					if (null==text || text.length<1){
						return null
					}else{
						if (record.yk_contract_url) {
							return (<a target='_blank' href={record.yk_contract_url}>{record.waybill_no}</a>)
						} else {
							return (<span>{record.waybill_no}</span>)
						}
						return (<span>{record.waybill_no}</span>)
					}
				}
			},{
				title:'客户名称',
				dataIndex: 'client_name',
				key:'客户名称',
				width:150,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'客单总金额',
				dataIndex: 'client_total_fee_total',
				key:'客单总金额',
				width:100,
				render:text =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'开票号码',
				dataIndex: 'offerd_invoice_no',key:'开票号码',
				width:80,
				render:(text) =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'受票方',
				dataIndex: 'offerd_invoice_unit_name',key:'受票方',
				width:80,
				render:(text) =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'开票金额',
				dataIndex: 'client_total_fee_total',key:'开票金额',
				width:80,
				render:(text) =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{text}</span>)
					}
				}
			},{
				title:'开票日期',
				dataIndex: 'invoice_date',key:'客运单开票日期',
				width:100,
				render:(text) =>{
					if (null==text || text.length<1){
						return null
					}else{
						return (<span>{moment(text).format('YYYY-MM-DD')}</span>)
					}
				}
			},]},

		{
			title: '校验',
			children:[
			// 	{
			// 	title:'人车货照片',
			// 	dataIndex: 'receipt_file',
			// 	width:160,
			// 	render:(text,record) =>{
			// 		if (text) {
			// 			let url = text.split(",");
			// 			//定义返回的组件
			// 			let element=[];
			// 			//循环的组件
			// 			for (var i = 0; i < url.length; i++) {
			// 				element.push(<p key={i}><a target='_blank' href={url[i]}>{url[i]}</a></p>);
			// 			}
			// 			const content = (
			// 				<div>
			// 					{element}
			// 				</div>
			// 			);
			//
			// 			return (<Popover rowKey="id" content={content} title="图片地址">
			// 				<Button type="primary">正常</Button>
			// 			</Popover>)
			// 		}else {
			// 			return (
			// 			<span style={{color:"red"}}>异常</span>
			// 			)
			// 		}
			// 	}
			// },
				{
				title:'司机证件照',
				dataIndex: '',
				width:160,
				render:(text,record) =>{
					if(!record.driver_front_path && !record.transport_img && !record.id_photo_correct_img){
						return (
							<span style={{color:"red"}}>异常</span>
						)
					}else {
						return "正常";
					}
				}
			},{
				title:'金额一致',
				dataIndex: 'moenyflag',
				width:160,
				render:(text) =>{
					let isOk = text!=1 ? <span style={{color:"red"}}>异常</span>:'正常'
					return (<span>
						{isOk}
						</span>)
				}
			},
			// 	{
			// 	title:'正常开票',
			// 	dataIndex: '',
			// 	width:160,
			// 	render:(text,record) =>{
			// 		if(record.invoice_date && record.actual_arrival_time){
			// 			// 计算两个日期之间的差值
			// 			let totalDays,diffDate;
			// 			let myDate_1 = Date.parse(record.invoice_date);
			// 			let myDate_2 = Date.parse( record.actual_arrival_time);
			// 			// 将两个日期都转换为毫秒格式，然后做差
			// 			diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值
			// 			totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
			// 			if(totalDays<=60 ){
			// 				return '正常';
			// 			}else {
			// 				return  (<span style={{color:"red"}}>异常</span>);
			// 			}
			// 		}
			// 	}
			// },
				{
				title: '实名认证',
				dataIndex: 'zqsign_flag',
				width: 160,
				render: (text) => {
					if (text != 0) {
						return '正常';
					} else {
						return (<span style={{ color: "red"}}>异常</span>);
					}
				}
			},
			]
		}


	];

	const rowSelection = {
		selectedRowKeys,
		selectedRows,
		onSelect: (record, selected, selectedRows, nativeEvent)=>{
			if(selected){set({selectedRowKeys: [record.id], selectedRow: record, selectedRows: [record]})
			} else {set({selectedRowKeys: [], selectedRow: {}, selectedRows: []})}
		},
		//onChange: (selectedRowKeys, selectedRows) => { SET({selectedRowKeys, selectedRows}) }
	};

	pagination.onChange =(page, pageSize)=>{
		if (isNaN(pageSize) ){
			//这是异常刷新，不记录任何值,比如排序刷新
			return;
		}
		let paginationNew ={...pagination};
		if(page>187){
			page =parseInt(page/11)
		}
		paginationNew.current=page;  //如果page是1，直接提交即可
		paginationNew.pageSize =pageSize;
		set({pagination:paginationNew,dataLoading:true});

	}

	pagination.onShowSizeChange =(current, size)=>{
		let paginationNew ={...pagination};
		let overcount =paginationNew.pageSize * (paginationNew.current-1);

		paginationNew.current  =1 + parseInt(overcount/size);

		paginationNew.pageSize =size;
		set({pagination:paginationNew,dataLoading:true});

	}

	const tabelDefinition = {
		rowKey: 'carrier_waybill_id',
		loading: dataLoading,
		pagination:pagination,
		size: 'small',
		columns: columns,
		dataSource: data,
		scroll:{x:800, y: window.innerHeight-370},
		//expandedRowRender: record => <p style={{ margin: 0}}>{JSON.stringify(record.m_orderpay)}</p>,
		//rowSelection: rowSelection,
		bordered:true,
		onRow: (record, index) => { return { onClick: event => set({selectedRow:record, selectedRowIndex: index})}},
		rowClassName: (record, index) => index === selectedRowIndex ? 'selectedItem' : '',
	}
	const LoadingModalProps = {
		setVisible: exportLoading,
	}
	const ExportLoadLoadingModal = () => <ExportWaitModal {...LoadingModalProps}/>;
	const formRenderProps={
		searchProps:searchProps,
		onFinish(data){
			// 1  先清空分页中原有的当前页，pagination.current =1
			// 2 将搜索内容更新到 searchProps内。
			// 3 更新dataLoading 为true。

			let  paginationNew = {...pagination}
			paginationNew.current =1;

			// 字段特殊处理
			let dataFetchPropsTmp = {...dataFetchProps};

			set({dataLoading:true,pagination:{...paginationNew},searchProps:{...dataFetchPropsTmp,...data}});

		},

		onReset(){
			// 重置查询查询

			set({dataLoading:true,searchProps:{},dataFetchProps:dataFetchPropsInit});
		},
		//导出全部功能
		onExcelAll(){
			let props ={...dataFetchProps,...searchProps,pageSize: 1000};
			let Axios = AxiosServer();
			set({exportLoading:true});
			return Axios.post('/api/excel/export/waybillList',props).then((res)=>{
				set({exportLoading:false});
				if (res.data.filename){
					let Axios1 = AxiosServer();
					download2(Axios1.defaults.baseURL+ res.data.filename,'');
				}else{
					return '下载失败';
				}
			}).catch((err)=>{
				message.error('错误:' + err.message,10);
				return '错误'
			})
		},

	}



	return (


		<div>
			<div className="site-button-ghost-wrapper" >
				<Button ghost onClick={openHelp}>字段说明</Button>

			</div><br/>
			<Row>
				<Col span={24}>
					<SearchRender {...formRenderProps} />
				</Col>


			</Row>

			<Table {...tabelDefinition}/>

			{waybListHelpVisible && <WaybListHelp visible = {waybListHelpVisible} onCancel ={()=>{set({waybListHelpVisible:false})}}/>}
			{exportLoading && <ExportLoadLoadingModal />}
		</div>);


}


export default List;
