import React from 'react';
import {Drawer,Descriptions} from 'antd';

const WaybListHelp =(props)=>{
	
	const {onCancel,visible} =props
	
	let width = window.innerWidth <768 ? window.innerWidth*0.8 :window.innerWidth*0.35
	return (
	
	<Drawer   title="运单字段说明"    placement="right"      closable={false}      onClose={onCancel}    visible={visible}  width={width}  >
		<p>本说明是对运单表的字段的说明</p>
		<p>货主端:</p>
		<Descriptions  bordered>

			<Descriptions.Item label="客户单号"> 客户运单号</Descriptions.Item><br/>
			<Descriptions.Item label="货物名称">货物名称</Descriptions.Item>
			<Descriptions.Item label="客单总金额">客户运单上的总费用</Descriptions.Item>
			<Descriptions.Item label="开票号码">开出发票的号码</Descriptions.Item>
			<Descriptions.Item label="受票方">开出发票的主体</Descriptions.Item>
			<Descriptions.Item label="开票金额">开出发票的金额</Descriptions.Item>
			<Descriptions.Item label="开票日期">开出发票的日期</Descriptions.Item>
		  </Descriptions><br/>

		<p>承运端</p>
		<Descriptions  bordered>

			<Descriptions.Item label="承运单号">承运端的运单号</Descriptions.Item>
			<Descriptions.Item label="承运人">接单方，承运商或车主</Descriptions.Item>
			<Descriptions.Item label="收票金额">收到发票金额</Descriptions.Item>
			<Descriptions.Item label="收到发票主体">收到的发票主体</Descriptions.Item>
			<Descriptions.Item label="收到发票开票日期">收到的发票日期</Descriptions.Item>
			<Descriptions.Item label="收到发票号码">收到的发票号码</Descriptions.Item>
			<Descriptions.Item label="类型">汽运/船运。显示为车或船</Descriptions.Item>
			<Descriptions.Item label="核载">单位为吨</Descriptions.Item>
			<Descriptions.Item label="获票方式">01承运商自行开票 02委托代征系统开票</Descriptions.Item>
			<Descriptions.Item label="里程数">目前是由人工采集的</Descriptions.Item>
		</Descriptions><br/>

		<p>校验</p>
		<Descriptions  bordered>

			<Descriptions.Item label="主体">承运人、开票主体、司机一致   如果未开票的仅校验承运商和司机是否一致</Descriptions.Item>
			<Descriptions.Item label="金额">开票金额、承运金额、付款金额一致</Descriptions.Item>
			{/*<Descriptions.Item label="重量">客户合同重量与承运合同重量一致</Descriptions.Item>*/}
			<Descriptions.Item label="超载">运单总重不超过核载</Descriptions.Item>
			<Descriptions.Item label="获票方式">01承运商自行开票 02委托代征系统开票</Descriptions.Item>
			<Descriptions.Item label="里程数">目前是由人工采集的</Descriptions.Item>
		</Descriptions><br/>

	</Drawer>
	
	)

}


export default WaybListHelp;
