import React from 'react';
import {Tag} from 'antd';


function TestColor() {

	return (
		<div >
			<span>标签背景色</span><br/> <br/>
			<div style ={{whiteSpace:'normal',widht:'100%',height:'100%',wordWrap:'break-word',overflow:'auto'}}>
				WEB通用色<br/>
				<Tag color="#CC0000">#CC0000</Tag>
				<Tag color="#FF0000">#FF0000</Tag>
				<Tag color="#f50">#f50</Tag>
				<Tag color="#FFCC00">#FFCC00</Tag>
				<Tag color="#2db7f5">#2db7f5</Tag>
				<Tag color="#87d068">#87d068</Tag>
				<Tag color="#2E8B57">#2E8B57</Tag>
				<Tag color="#108ee9">#108ee9</Tag>
				<Tag color="#1E90FF">#1E90FF</Tag>
				<Tag color="#08c">#08c</Tag>
				<Tag color="#5F9EA0">#5F9EA0</Tag>
				<Tag color="#DCDCDC">#DCDCDC</Tag>
				<Tag color="#808080">#808080</Tag>
				<Tag color="#000000">#000000</Tag>
				<Tag color="#FFFFFF"><font color="#000000">#FFFFFF</font></Tag>
				<Tag color="#F5F5F5" ><font color="#000000">#F5F5F5</font></Tag>
				<Tag color="#BAE7FF">#BAE7FF</Tag>
				<hr/>大经主色调<br/>
				<Tag color="#0072BC">#0072BC</Tag>
				<Tag color="#f0621a">#f0621a</Tag>
				<Tag color="#2e6eb8c"> <font color ='#000000'>#2e6eb8</font></Tag>
				<Tag color="#d6a74e"> #d6a74e</Tag>
				<Tag color="#004071"> #004071</Tag>
				<hr/>
			</div>

		</div>

	);
}


export  default TestColor;
