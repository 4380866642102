import {Row,Col,Form,  Input, Button, message} from 'antd';
import {AxiosServer} from '../utils';
import './Login.css';
import djlogo from './djlogo.png';
import { UserOutlined, LockOutlined, } from '@ant-design/icons';
import React,{useState,useContext,} from 'react';

function Login() {


	//清空sessionStorage历史
	sessionStorage.removeItem('user_name');
	sessionStorage.removeItem('roleid');
	sessionStorage.removeItem('token');
	sessionStorage.removeItem('groupid');
	sessionStorage.removeItem('id');


	/*登陆页面简化处理，核心只做三件事情
	* 1 提交页面入参到后端。
	* 2 从后端获取token。
	* 3 token写入sessionStorage.
	* 其他不相关的由App进行处理
	 */

	//定义变量
	let initState ={
		modalVisible:true,
	};
	const [state,setState] =useState(initState);

	const handleSubmit = (values) => {
		message.info('登陆...')
		values.password = new Buffer.from(values.password + '@123').toString('base64');
		let Axios =new AxiosServer();
		Axios.post('/api/login',values).then(res=>{
			message.destroy()
			//登陆结果判断
			if (!res.data.status || res.data.status ==='failed' ){
				let info =res.data.msg ?res.data.msg:'登陆失败'
				message.error(info)
			}else{
				if (!res.data.data.roleid){
					message.error('无权限或配置权限不正确')
					return;
				}
				let token =res.headers['token'];
				message.success('登陆成功')
				//写入sessionStorage.，以便保持长久在线
				sessionStorage.setItem('token',token);
				sessionStorage.setItem('id',res.data.data.id);
				sessionStorage.setItem('user_name',res.data.data.user_name);
				sessionStorage.setItem('roleid',res.data.data.roleid);
				sessionStorage.setItem('groupid',res.data.data.groupid);
				if (autoLogin ){
					//如果autoLogin为true，表示可以直接登录
					window.location.href='/wayb/listForForm';
				}else {
					window.location.href='/';
				}
			}

		}).catch(err=>{
			console.log(err);
			message.error(err.message)

		})
	}

	//2 ****如果url参数中有token和roleid字段，则自动登陆  ****

	// 2.1 获取url参数
	const  getQueryVariable = (variable) =>
	{

		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return pair[1];}
		}
		return(false);
	}

	// const AutoLoginToken = getQueryVariable( 'token');
	// const randnum=  getQueryVariable( 'randnum');
	var query = window.location.search;
	var randnum = query.substring(query.indexOf("randnum=")+8,query.indexOf("&"))
	var AutoLoginToken= query.substring(query.indexOf("token=")+6);
	let autoLogin = false;
	if(randnum){
		//对randnum+"luxiaoer"进行加密，然后对AutoLoginToken值比较，如果相等的话，则autoLogin改为true
		console.log(new Buffer.from(randnum+"luxiaoer").toString('base64'));
		if((new Buffer.from(randnum+"luxiaoer").toString('base64'))==AutoLoginToken){
			autoLogin = true;
		}
	}
	//2.2 自动登陆
	if (autoLogin){
		handleSubmit({user_name:'guest',password:'123'});
		return        (<div>AutoLogin</div>);
	}


	// *** 2 自动登陆代码  end ***

	return (
		<div >
			<Row  ><img src={djlogo} alt={'logo pic'} width='200px' style={{marginTop:'20px',marginLeft:'10px' ,paddingLeft:'10px',paddingRight:'10px'}}/></Row>
			<Row type="flex" justify="center" align="middle"   >
				<div >
					<Row type="flex" justify="center" align="middle"  style={{ minWidth:'100vh',minHeight:'70vh' , }}>
						<Col  align="middle" style={{marginTop:'5px'}}>
							<br/><h1 >
							<span style={{color:'#DCDCDC'}}>货运导航监管软件</span>
							</h1>		<br/>
							<div style={{ width:'280px'}}>

								<Form onFinish={handleSubmit} className="login-form" style={{paddingLeft:'10px',paddingRight:'10px'}}  >
									<Form.Item  name="user_name"
												rules={[
													{required: true,message: 'Please input your userName',},
												]}
									>
										<Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
									</Form.Item>

									<Form.Item  name="password"
												rules={[
													{required: true,message: 'Please input your userName',},
												]}
									>
										<Input prefix={ <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
									</Form.Item>

									<Form.Item >

										<Button type="primary" htmlType="submit" className="login-form-button">
											Log in
										</Button>

									</Form.Item>

								</Form> </div>

						</Col>

					</Row>
				</div>
			</Row>

		</div>

	);
}


export {Login};

