import React,{useContext}from 'react';
import {APPContext} from "../reducer";
import { Redirect,Link, Route, Switch } from 'react-router-dom';
import {  Layout, Menu,  Col, Row , Dropdown, ConfigProvider ,Button,message } from 'antd';
import { UserOutlined,SettingOutlined,LogoutOutlined} from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import {OfflineModal} from '../components';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {asyncComponent} from '../utils/asyncComponent';
import djlogo from './djlogo.png';
import {menuStandard} from "../config";
import {AxiosServer} from "../utils";


// 定时前端时间格式
moment().utcOffset('+08:00');

const Axios =new AxiosServer();

const {   Content, Footer, Sider,} = Layout;
const SubMenu = Menu.SubMenu;

//需要依赖APPContext 的必须放到App函数内。其他函数和功能可以放到App之外


const getIcon =(iconName)=>{

    let  Icon = require('@ant-design/icons')[iconName];
    return (<Icon  />);
}

// 主函数，运行的组件
function App() {

    const appCtx = useContext(APPContext);

    //测试是否脱机
    window.addEventListener('online', function(){
        console.log('online')
        appCtx.dispatch({offline:false});

    });
    window.addEventListener('offline', function(){
        console.log('offline')
        appCtx.dispatch({offline:true});
    });

    window.onresize=()=>{
        appCtx.dispatch({screenType:window.innerWidth <780?'mb':'pc'});  //暂时只区分两类
    };

    // 用户登陆信息 设默认值
    let menuRun=[];  //运行的菜单
    let urlListRun =[{url:'/404',comp:'./E404'}]; // 可以访问的路由,默认值添加404;
    let roleid =sessionStorage.getItem('roleid');
    let user_name =sessionStorage.getItem('user_name');
    let token =sessionStorage.getItem('token');

    if ( user_name ==='' || user_name ===null || token ==='' || token ===null){
        return (<Redirect to="/login"  />)
    }

    const {offline,screenType,loginUserRoleAuth} =appCtx.story

    const getRoleAuth =async ()=>{

        let res = await Axios.post('/menuAuth/findAll', {roleid:roleid});
        if (res.data.status == true && res.data.count >0 ){
            //如果成功，将res.data.data中的menu解构出来，取checkedKeys赋值。
            let resdata= res.data.data[0];
            const {checkedKeys,halfCheckedKeys} =resdata.menu;
            if (!checkedKeys){
                message.error('无权限');
                return (<span>无权限</span>);
            }
            let userRoleAuth =checkedKeys.concat(halfCheckedKeys);
            appCtx.dispatch({loginUserRoleAuth:userRoleAuth});
        }else{
            console.log('无权限');
            appCtx.dispatch({loginUserRoleAuth:[]});
            return (<span>无权限</span>)
        }


    }


    if (loginUserRoleAuth ===-1){
        getRoleAuth();
    }else {
        // 处理菜单与路由权限


        menuStandard.map((obj,index)=>{
            let NewItem=[];
            if (obj.Item){
                //Item是具体的菜单

                let ItemObj ={};
                obj.Item.map((d,i)=>{
                    if (loginUserRoleAuth.indexOf(d.url) !== -1){
                        ItemObj ={...d};
                        NewItem.push(ItemObj);
                        if (d.component){
                            urlListRun.push({url:d.url,comp:d.component});
                        }

                    }
                })
            }
            if (loginUserRoleAuth.indexOf(obj.url) !== -1){   //如果菜单有，则添加，否则不添加
                menuRun.push({...obj,Item:NewItem})
            }
        })
    }

    // 定义动态加载组件的函数getCompt('./Home')
    let getCompt =(urlName)=>asyncComponent(() => import(`${urlName}`));

    const menuTop = (
        <Menu >
            <Menu.Item key={'/login'}>
                <Link to='/login'  ><LogoutOutlined /> 退出登陆</Link>
            </Menu.Item >
            <Menu.Item key={'/pwd'}>
                <Link to='/setting/password' ><SettingOutlined /> 修改密码</Link>
            </Menu.Item>
        </Menu>
    );




    return (
    <div >

        <ConfigProvider locale={zhCN}>

            <Layout  style={{ minHeight: '100vh' }}>
                <Row style={{ background: '#004071' ,padding:'15px 0 15px 5px',fontSize: '18px', color: '#FFFFFF' }}>
                    <Col span={3} ><img src={djlogo} width='100px' alt={'img'} />	</Col>
                    <Col span={2} offset={19} ><span style={{ textAlign: 'right'}} >
                <Dropdown overlay={menuTop} placement="bottomCenter">
                <a ><UserOutlined style={{ fontSize: '24px', color: '#FFCC00' }}/>
                <span  style={{ fontSize: '20px', color: 'white', marginLeft: '5px', marginRight: '15px' }}>  </span></a>
                </Dropdown>
                </span></Col>
                </Row>
                <Layout >
                    <Sider  style={{ background: '#fff' }}
                            trigger={null}
                            collapsible
                            collapsed={screenType =='pc'? false:true}
                    >


                        <div className="logo" />

                        <Menu theme="white" mode= {screenType =='pc'? "inline":"vertical"}  defaultOpenKeys={["wayb"]} >
                            {menuRun ? menuRun.map(obj=>{
                                    if(obj.Item){
                                        //添加到SubMenu

                                        return (
                                            <SubMenu key={obj.url}  title={obj.title} icon={getIcon(obj.Icon?obj.Icon:'AppstoreOutlined')} >
                                                {obj.Item ?obj.Item.map(d=>{
                                                        //添加Item
                                                        return (
                                                            <Menu.Item key={d.url} icon={getIcon(d.Icon?d.Icon:'AppstoreOutlined')}  ><Link to={d.url}><span>{d.title}</span></Link></Menu.Item>
                                                        )
                                                    }
                                                ):false}
                                            </SubMenu> )
                                    } else {

                                        return ( <Menu.Item key={obj.url} icon={getIcon(obj.Icon?obj.Icon:'AppstoreOutlined')} >
                                        <span>  <Link to={obj.url}>{obj.title}</Link></span></Menu.Item>
                                        )
                                    }
                                }
                            ):false}
                        </Menu>

                    </Sider>

                    <Layout>

                        <Content style={{
                            margin: '15px 15px 15px 15px', padding: 10, background: '#fff', minHeight: 280, minWidth:'20vh',
                        }}>
                            <Switch>
                                <Route key='/'  exact path="/" component={getCompt('./Home')}/>
                                { urlListRun.map((r,i)=><Route key={i} exact path={r.url}  component={getCompt(r.comp) } /> )}
                                <Redirect to="/"  />
                            </Switch>
                        </Content>

                        {/*} <Footer style={{ textAlign: 'center' ,marginTop:'-30px',marginBottom:'-20px'}}>
                本软件由江苏角马智慧供应链有限公司提供技术支持
          </Footer>*/}
                    </Layout>
                </Layout>
            </Layout>
            {offline&&<OfflineModal />}
        </ConfigProvider>

    </div>

    );
}



export {App};

