import {createContext}from 'react';

const InitStory = {
    app: {},
    loginUser: null,
    offline:false,
    screenType:'pc',
    loginUserRoleAuth:-1,

}

 function reducer(story, newStory ) {
     return { ...story,...newStory};
}

const APPContext =createContext(null);

export  {reducer,InitStory,APPContext}